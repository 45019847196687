import React from 'react'
import { StaticQuery, Link, graphql } from 'gatsby'
import { orderBy } from 'natural-orderby'

function SuperSecretSitemap() {
    return (
        <StaticQuery
            query={graphql`
                query SitemapQuery {
                    allDataJson(sort: { fields: title, order: ASC }) {
                        edges {
                            node {
                                slug
                                title
                            }
                        }
                    }
                }
            `}
            render={data => (
                <div class="container is-fluid pt-4">
                    <h1 class="title is-2 has-text-centered">Super Secret Sitemap</h1>
                    <p class="subtitle is-5 has-text-centered">
                        <span role="img" aria-label="Shh!">
                            🤫  
                        </span>
                    </p>
                    <div class="has-text-centered">
                    {orderBy(data.allDataJson.edges, [v => v.node.title], ['asc']).filter(el => el.node.slug !== 'test-page').map(el => (
                            <>
                                <Link key={el.node.slug} to={"/" + el.node.slug}>{el.node.title}</Link> <br />
                            </>
                        )
                    )}
                    </div>
                </div>
            )}
        />
    )
}

export default SuperSecretSitemap